import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Container from 'components/shared/Container';
import { Row, Col } from 'styled-bootstrap-grid';
import SEO from 'components/shared/SEO';
import HeaderMini from 'components/Header/HeaderMini';
import { HeadingFour, Text, TextLarge } from 'components/shared/Typography';

import iconEnvelope from 'assets/icons/envelope.svg';
import iconPhone from 'assets/icons/phone-color.svg';

const Wrapper = styled.section`
  position: relative;
  overflow-x: hidden;
`;

const Content = styled.div`
  padding: 100px 0 70px 0;

  @media screen and (max-width: 992px) {
    padding: 60px 0 18px 0;
  }
`;

const Group = styled.div`
  margin: 0 0 45px 0;

  & ${HeadingFour} {
    margin: 0 0 20px 0;
  }
`;

const Label = styled(Text)`
  margin: 0 0 20px 0;
  font-weight: 600;
  text-transform: uppercase;
`;

const Textblock = styled(TextLarge)`
  & a {
    color: ${({ theme }) => theme.colors.blueVariant};
  }
`;

const Map = styled.div`
  position: relative;
  width: 75vw;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
  & iframe {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 992px) {
    left: -20px;
    width: 100vw;
    height: 100vw;
  }
`;

const headerCta = [
  {
    icon: iconPhone,
    link: 'tel:22 789 98 20',
    content: 'tel. 22 789 98 20',
  },
  {
    icon: iconEnvelope,
    link: 'mailto:warsztat@merc-des.pl',
    content: 'warsztat@merc-des.pl',
  },
];

const ContactPage = ({ data }) => {
  const PAGE = data.wpPage.PageContact;

  return (
    <>
      <SEO title="Kontakt" />
      <HeaderMini
        maxWidth="440px"
        subheading={PAGE.contactBannerSubheading}
        heading={PAGE.contactBannerHeading}
        text={PAGE.contactBannerText}
        background={
          PAGE.contactBannerImage.localFile.childImageSharp.gatsbyImageData
        }
        cta={headerCta}
      />
      <Wrapper>
        <Container>
          <Row>
            <Col lg={4}>
              <Content>
                <Group>
                  <Label>{PAGE.contactContent1Subheading}</Label>
                  <HeadingFour>{PAGE.contactContent1Subheading}</HeadingFour>
                  <Textblock
                    dangerouslySetInnerHTML={{
                      __html: PAGE.contactContent1Content,
                    }}
                  />
                </Group>
                <Group>
                  <Label>{PAGE.contactContent2Subheading}</Label>
                  <Textblock>
                    <a href={`tel:${PAGE.contactContent2Phone}`}>
                      tel: {PAGE.contactContent2Phone}
                    </a>
                  </Textblock>
                  <Textblock>
                    <a href={`mailto:${PAGE.contactContent2Email}`}>
                      e-mail: {PAGE.contactContent2Email}
                    </a>
                  </Textblock>
                  <br />
                <Textblock>
                <a href="tel:604 429 434">
                Wiesław: 604 429 434
                  </a>
                </Textblock>
                <Textblock>
                  <a href="tel:608 347 476">
                    Dorota: 608 347 476
                  </a>
                </Textblock>
                </Group>
                <Group>
                  <Label>{PAGE.contactContent3Subheading}</Label>
                  <Textblock
                    dangerouslySetInnerHTML={{
                      __html: PAGE.contactContent3Content,
                    }}
                  />
                </Group>
              </Content>
            </Col>
            <Col lg={8}>
              <Map
                dangerouslySetInnerHTML={{
                  __html: PAGE.contactMapIframe,
                }}
              />
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </>
  );
};

ContactPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query queryPageContact {
    wpPage(slug: { eq: "kontakt" }) {
      PageContact {
        contactBannerSubheading
        contactBannerHeading
        contactBannerText
        contactBannerImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: BLURRED
                formats: [WEBP]
              )
            }
          }
        }
        contactBannerText
        contactBannerCtas {
          contactBannerCtasUrl
          contactBannerCtasIcon {
            mediaItemUrl
          }
          contactBannerCtasContent
        }
        contactContent1Content
        contactContent1Heading
        contactContent1Subheading
        contactContent2Email
        contactContent2Phone
        contactContent2Subheading
        contactContent3Content
        contactContent3Subheading
        contactMapIframe
      }
    }
  }
`;

export default ContactPage;
